import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from "axios";
import layer from "layui-layer";
import './assets/css/global.css'
import VueCookie from 'vue-cookie'
import echarts from 'echarts'

// 超时时间
axios.defaults.timeout = 20 * 1000
// 凭证
axios.defaults.withCredentials = true

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  console.log(config)
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

Vue.prototype.$http = axios
Vue.prototype.$layer = layer
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(VueCookie)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
