import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import(/**/'../components/Login')
  },
  {
    path: '/',
    redirect: '/info',
    component: () => import(/* webpackChunkName: "about" */ '../components/Index'),
    children: [
      {
        name: "Info",
        path: '/info',
        component: () => import(/* webpackChunkName: "about" */ '../components/Info')
      }, {
        name: "List",
        path: '/list',
        component: () => import(/* webpackChunkName: "about" */ '../components/List')
      }, {
        name: "Count",
        path: '/count',
        component: () => import(/* webpackChunkName: "about" */ '../components/Count')
      }, {
        name: "Map",
        path: '/map',
        component: () => import(/* webpackChunkName: "about" */ '../components/Map')
      }, {
        name: "Chars",
        path: '/chars',
        component: () => import(/* webpackChunkName: "about" */ '../components/Chars')
      }]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
    return;
  }
  console.log(Vue.cookie.get('token'))
  console.log(Vue.cookie.get('content'))
  if (to.path !== '/login' && (Vue.cookie.get('token') === null || Vue.cookie.get('content') === null||
    Vue.cookie.get('token') === '' || Vue.cookie.get('content') === '')) {
    next("/login");
    return;
  }
  next();
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
