<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'app',
    data() {
      return {}
    },
    computed: {
    },
    created() {
    },
    methods: {

    }
  }
</script>

<style>
  #app {
    min-width: 1200px;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
</style>
